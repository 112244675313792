@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
  scroll-behavior: smooth;
}

*{
  pointer-events: none;
}

body{
  background-color: black;
  overflow-x: hidden;
}

.pointer-events-auto{
  transition: all 0.5s;
}

span, a{
  pointer-events: all;
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 2px white;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px white;
  }
}

.move-in-text-1{
  animation: move-from-left 15s infinite;
}

.move-in-text-2{
  animation: move-from-right 15s infinite;
  animation-delay: 5s;
  opacity: 0;
}

.move-in-text-3{
  animation: move-from-left 15s infinite;
  animation-delay: 10s;
  opacity: 0;
}

@keyframes move-from-left {
  0% {
    left: 100px;
    opacity: 0;
  }

  2% {
    left: 0px;
    opacity: 1;
  }

  31%{
    left: 0px;
    opacity: 1;
  }

  33.5%{
    left: 100px;
    opacity: 0;
  }

  100%{
    left: 100px;
    opacity: 0;
  }
}

@keyframes move-from-right {
  0% {
    right: 100px;
    opacity: 0;
  }

  1.5% {
    right: 0px;
    opacity: 1;
  }

  33%{
    right: 0px;
    opacity: 1;
  }

  35.5%{
    right: 100px;
    opacity: 0;
  }

  100%{
    right: 100px;
    opacity: 0;
  }
}

.slick-slide{
  display: flex !important;
  justify-content: center;
  padding-left: 14px;
  padding-right: 14px;
}

.landing-slider .slick-slide img{
  max-height: 10vh !important;
}

.slick-track{
  display: flex !important;
  align-items: center;
}

@media screen and (max-width: 770px) {
  .service-box{
    height: 390px !important;
    width: 90vw !important;
    padding: 0.75rem !important;
    overflow: hidden;
    position: relative;
    left: 5px;
  }

  .testimonial-box{
    height: 450px !important;
  }

  .testimonial-box span{
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 9 !important;
    margin-top: 7px;
  }

  .testimonial-box img:last-of-type{
    margin-bottom: 20px !important;
  }

  .other-case-study{
    width: 100%;
  }

  .increase-margin-on-collapse{
    margin-top: 75px;
  }

  .visible-on-collapse{
    display: flex;
  }

  .hide-testimonials{
    display: none;
  }

  .style-testimonial{
    font-style: italic;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
  }

  .style-testimonial span{
    font-size: 3rem;
    line-height: 1;
  }
}

@media screen and (max-width: 800px){
  .footer-media{
    flex-direction: column !important;
  }

  .footer-media span{
    margin-right: 15px;
  }

  .footer-column-2{
    align-items: start;
    padding-top: 2rem;
  }

  .footer-row-on-sm{
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
  }

}


@media screen and (max-width: 900px) {
  .case-media{
    flex-direction: column;
    align-items: center;
  }

  .case-full-width{
    width: 100% !important;
  }

  .case-studies{
    background: transparent;
    min-height: 0;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    color: #CDC8B6;
    gap: 10px;
    padding-top: 0px;
    padding-bottom: 12px;
  }

  .case-studies span:last-child{
    margin-top: 2px;
  }

  .case-studies span{
    opacity: 1;
    font-family: "Playfair Display", "serif" !important;
    font-style: italic;
    font-size: 3rem !important;
    line-height: 1;
  }

}

@media screen and (max-width: 768px) {
  .case-height-query{
    height: 425px !important;
  }

  .nfts-collapse{
    flex-direction: column;
    height: auto !important;
    padding: 20px;
  }

  .nfts-hidden-on-collapse{
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .case-height-query{
    height: 400px !important;
  }
}

@media screen and (max-width: 400px) {
  .collapse-on-400{
    flex-direction: column-reverse;
    align-items: start;
  }
}


@media screen and (max-width: 920px) {
  .case-920-col{
    flex-direction: column;
    align-items: start;
  }
}

.slick-dots button::before{
  color: white !important;
  font-size: 9px !important;
  top: 5px !important;
}

.landing-slider.slick-slider, .client-slider.slick-slider{
  mask-image: linear-gradient(to right,hsl(0 0% 0% / 0) 11%,hsl(0 0% 0% / 1) 30%,hsl(0 0% 0% / 1) 70%,hsl(0 0% 0% / 0) 89%);
}

@media screen and (min-width: 1800px) {
  .slick-slider{
    mask-image: linear-gradient(to right,hsl(0 0% 0% / 0) 10%,hsl(0 0% 0% / 1) 25%,hsl(0 0% 0% / 1) 75%,hsl(0 0% 0% / 0) 90%);
  }
}

@media screen and (max-width: 400px) {
  .line-clamp{
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 4 !important;
    max-width: 90%;
  }
}

.perma-line-clamp{
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important;
  max-width: 90%;
}

@media screen and (min-width: 450px) {
  .perma-line-clamp{
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 6 !important;
    max-width: 90%;
  }
}

.fade-bg-y{
  mask-image: linear-gradient(to bottom,hsl(0 0% 0% / 0) 0%,hsl(0 0% 0% / 1) 20%,hsl(0 0% 0% / 1) 80%,hsl(0 0% 0% / 0) 100%);
}

@media screen and (max-width: 600px) {
  .fade-bg-y{
    mask-image: linear-gradient(to bottom,hsl(0 0% 0% / 0) 0%,hsl(0 0% 0% / 1) 10%,hsl(0 0% 0% / 1) 90%,hsl(0 0% 0% / 0) 100%);
  }
}

.nfts-selected-box img{
  filter: drop-shadow(0px 1000px 0 #627ECA);
  transform: translateY(-1000px);
}

.nfts-selected-box{
  background: rgba(0, 0, 0, 0.495) !important;
  background-image: linear-gradient(90.4deg, rgba(98, 126, 202, 0.1) 0.69%, rgba(155, 178, 240, 0.072) 103.89%) !important;
}

.nfts-selected-box span{
  color: #627ECA;
}

.selected-time-frame{
  background-color: #192033 !important;
  color: #627ECA !important;
}

.search-input-bar{
  background: url(../public/search.png) no-repeat scroll 10px 10px;
  background-position: -26px center;
  background-origin: content-box;
}

.select-box{
  appearance: none;
  background-color: #FFFFFF0D !important;
  background-image: none;
}

.small-gradient-box{
  background: rgba(0, 0, 0, 0.495) !important;
  background-image: linear-gradient(125.89deg, rgba(216, 226, 255, 0.1) -27.78%, rgba(49, 62, 100, 0.026) 108.51%) !important;
}

.selected-small-gradient-box{
  background: rgba(0, 0, 0, 0.495) !important;
  background-image: linear-gradient(125.89deg, rgba(98, 126, 202, 0.3) 0%, rgba(49, 62, 100, 0.078) 120%) !important;
}

.opaque{
  opacity: 1 !important;
}

@media screen and (min-width: 1900px) {
  .landing-query-pb{
    padding-bottom: 6rem !important;
  }

  .landing-query-5rem{
    font-size: 5rem;
  }

  .landing-query-30px{
    font-size: 30px;
  }

  .landing-query-35px{
    font-size: 35px;
  }

  .landing-query-135rem{
    font-size: 1.35rem;
  }

  .landing-padding-bottom{
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 970px) {
  .faqs-query-970{
    padding-left: 24px;
    padding-right: 24px;
  }

  .faqs-query-parent{
    padding-left: 40px;
    padding-right: 40px;
  }
}

.line-h{
  line-height: 130% !important;
}
